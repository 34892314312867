import { useEffect, useState } from "react";
import { grey } from "@mui/material/colors";
import useWebSocket from "react-use-websocket";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import StyledDialogTitle from "../DialogTitle";

const addPayload = (payload) =>
  `Incoming Request at: ${new Date().toLocaleTimeString()}\n\n${JSON.stringify(payload)}\n\n=============`;

const CredentialLogsModal = ({ open, handleClose }) => {
  const [logs, setLogs] = useState([]);
  const { lastJsonMessage } = useWebSocket(`ws://${window.location.host}`, {
    share: true,
  });

  useEffect(() => {
    console.log("lastJsonMessage", lastJsonMessage);

    if (lastJsonMessage) {
      setLogs((prevLogs) => [...prevLogs, addPayload(lastJsonMessage)]);
    }
  }, [lastJsonMessage]);

  return (
    <Dialog fullWidth maxWidth="md" open={open}>
      <StyledDialogTitle onClose={handleClose}>
        Provider Credentials Logs
      </StyledDialogTitle>
      <DialogContent dividers>
        <TextField
          rows={23}
          multiline
          fullWidth
          value={logs.join("\n\n")}
          InputProps={{
            readOnly: true,
          }}
          sx={{
            position: "relative",
            ".MuiInputBase-root": {
              fontFamily: "Menlo, monospace",
              fontSize: "14px",
              bgcolor: grey[100],
            },
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CredentialLogsModal;
