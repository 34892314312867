import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

import StyledButton from "../Button";
import StyledDialogTitle from "../DialogTitle";
import { transferData } from "../../redux/reducers/edcSlice";

const EDCDataTransferModal = ({ open, handleClose, selectedAsset }) => {
  const dispatch = useDispatch();

  const { negotiationStatus } = useSelector((state) => state.edc);

  function handleDataTransfer() {
    dispatch(
      transferData({
        contractId: negotiationStatus["contractAgreementId"],
        assetId: selectedAsset["@id"],
      })
    ).then(() => {
      handleClose();
    });
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <StyledDialogTitle onClose={handleClose}>Transfer Data</StyledDialogTitle>
      <DialogContent dividers>
        <Typography
          variant="caption"
          sx={{ textTransform: "uppercase", fontWeight: "bold" }}
        >
          Asset ID
        </Typography>
        <Typography mb={2}>{selectedAsset["@id"]}</Typography>
        <Typography
          variant="caption"
          sx={{ textTransform: "uppercase", fontWeight: "bold" }}
        >
          Contract Agreement ID
        </Typography>
        <Typography mb={2}>
          {negotiationStatus["contractAgreementId"]}
        </Typography>
      </DialogContent>
      <DialogActions>
        <StyledButton
          variant="contained"
          onClick={handleDataTransfer}
        >
          Transfer
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default EDCDataTransferModal;
