import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import ContractIcon from "@mui/icons-material/Gavel";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

import EDCDataTransferModal from "../../components/edc-data-transfer-modal";
import CredentialLogsModal from "../../components/edc-credential-logger-modal";

import {
  fetchEDCInfo,
  fetchEDCCatalog,
  negotiateContract,
  setProviderAddress,
} from "../../redux/reducers/edcSlice";

const EDCConnector = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openLogs, setOpenLogs] = useState(false);
  const [selectedDataset, setSelectedDataset] = useState({});
  const { providerAddress, catalog } = useSelector((state) => state.edc);
  const [isEditing, setIsEditing] = useState(false);

  // If the catalog dataset is an object, convert it to an array
  const catalogData = {
    ...catalog,
    "dcat:dataset":
      catalog["dcat:dataset"]?.constructor === Object
        ? [catalog["dcat:dataset"]]
        : catalog["dcat:dataset"],
  };

  useEffect(() => {
    dispatch(fetchEDCInfo());
  }, [dispatch]);

  useEffect(() => {
    if (providerAddress && !isEditing) {
      dispatch(fetchEDCCatalog(providerAddress));
    }
  }, [dispatch, providerAddress, isEditing]);

  function handleNegotiate(asset) {
    setSelectedDataset(asset);
    dispatch(
      negotiateContract({
        contractId: asset["odrl:hasPolicy"]["@id"],
        assetId: asset["@id"],
      })
    ).then(() => {
      setOpen(true);
    });
  }

  function handleShowLogs() {
    setOpenLogs(true);
  }

  function handleEditClick() {
    setIsEditing((editing) => !editing);
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" fontWeight="bold" my={3}>
          EDC Connector
        </Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            label="Connector Endpoint"
            value={providerAddress}
            sx={{ minWidth: 400 }}
            onChange={(e) => dispatch(setProviderAddress(e.target.value))}
            InputProps={{
              readOnly: !isEditing,
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={isEditing ? "Save" : "Edit"} arrow>
                    <IconButton onClick={handleEditClick}>
                      {isEditing ? (
                        <SaveIcon fontSize="small" />
                      ) : (
                        <EditIcon fontSize="small" />
                      )}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            sx={{ fontWeight: "bold" }}
            onClick={handleShowLogs}
          >
            Show credential logs
          </Button>
        </Stack>
      </Stack>
      <Typography variant="h5" fontWeight="bold" mt={5} mb={3}>
        Catalog
      </Typography>
      <Grid container spacing={2}>
        {catalogData["dcat:dataset"]?.map((ct) => (
          <Grid item xs={12} sm={6} md={4} key={ct["@id"]}>
            <Paper sx={{ p: 2, height: "100%" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Stack direction="row" alignItems="center">
                  <ContractIcon fontSize="large" />
                  <Stack ml={2}>
                    <Typography variant="h6" fontWeight="bold">
                      {ct["@id"]}
                    </Typography>
                    <Typography variant="body2">{ct["name"]}</Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Button variant="contained" onClick={() => handleNegotiate(ct)}>
                Negotiate
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <EDCDataTransferModal
        open={open}
        handleClose={() => setOpen(false)}
        selectedAsset={selectedDataset}
      />
      <CredentialLogsModal
        open={openLogs}
        handleClose={() => setOpenLogs(false)}
      />
    </>
  );
};

export default EDCConnector;
