import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showAlert } from "./alertSlice";

import * as API from "../api";

const initialState = {
  providerAddress: "",
  catalog: {},
  negotiationStatus: {},
};

export const fetchEDCInfo = createAsyncThunk(
  "station/fetchEDCInfo",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.fetchEDCInfoAPI();
      return response.data;
    } catch (err) {
      const message = `Error fetching edc info (${err.message})`;
      dispatch(
        showAlert({
          message,
          options: {
            key: "station/fetchEDCInfoError",
            variant: "error",
          },
        })
      );
      return rejectWithValue(message);
    }
  }
);

export const fetchEDCCatalog = createAsyncThunk(
  "station/fetchEDCCatalog",
  async (providerAddress, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.fetchCatalogAPI(providerAddress);
      return response.data;
    } catch (err) {
      const message = `Error fetching Catalog (${err.message})`;
      dispatch(
        showAlert({
          message,
          options: {
            key: "station/fetchEDCCatalogError",
            variant: "error",
          },
        })
      );
      return rejectWithValue(message);
    }
  }
);

export const negotiateContract = createAsyncThunk(
  "station/negotiateContract",
  async (payload, { dispatch, rejectWithValue }) => {
    const loopRequest = async (negId) => {
      const status = await API.negotationStatusAPI(negId);
      if (!status.data.contractAgreementId) {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        return loopRequest(negId);
      } else {
        return status.data;
      }
    };

    try {
      const ngResp = await API.negotiateContractAPI(payload);
      const ngData = await loopRequest(ngResp.data["@id"]);
      return ngData;
    } catch (err) {
      const message = `Error negotiating contract (${err.message})`;
      dispatch(
        showAlert({
          message,
          options: {
            key: "station/negotiateContractError",
            variant: "error",
          },
        })
      );
      return rejectWithValue(message);
    }
  }
);

export const transferData = createAsyncThunk(
  "station/transferData",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      await API.dataTransferAPI(payload);
      dispatch(
        showAlert({
          message:
            "Data transfer successful. Please find credentials in http log server",
          options: {
            variant: "success",
          },
        })
      );
    } catch (err) {
      const message = `Error negotiating contract (${err.message})`;
      dispatch(
        showAlert({
          message,
          options: {
            key: "station/negotiateContractError",
            variant: "error",
          },
        })
      );
      return rejectWithValue(message);
    }
  }
);

const edcSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setProviderAddress: (state, action) => {
      state.providerAddress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEDCInfo.fulfilled, (state, { payload }) => {
      state.providerAddress = payload.providerAddress;
    });
    builder.addCase(fetchEDCCatalog.fulfilled, (state, action) => {
      state.catalog = action.payload;
    });
    builder.addCase(negotiateContract.fulfilled, (state, action) => {
      state.negotiationStatus = action.payload;
    });
  },
});

export const { setProviderAddress } = edcSlice.actions;

export default edcSlice.reducer;
